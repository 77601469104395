<template>
  <label :class="className">{{ labelTxt }} <slot></slot></label>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: ""
    },
    labelTxt: {
      type: String,
      required: false
    }
  }
};
</script>
