<template>
  <input type="radio" :value="label" :name="name" v-model="radioButtonValue" />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
      required: true
    },
    name: {
      type: String,
      default: "options",
      required: true
    },
    label: {
      type: String,
      default: "options"
    }
  },
  computed: {
    radioButtonValue: {
      get() {
        return this.value;
      },
      set() {
        // Communicate the change to parent component so that selectedValue can be updated
        this.$emit("handleRadioChange", this.label);
      }
    }
  }
};
</script>
