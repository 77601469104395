<template>
  <div-wrapper class="payment-cnt">
    <header class="inner-haed">
      <span-wrapper class="icon-Btn goback" @handleClick="goBack">
        <back-icon />
      </span-wrapper>
      <h1>{{ $t("_pay") }}</h1>
    </header>
    <section class="mid-cnt">
      <div-wrapper class="security-panel">
        <span-wrapper :txt="$t('_securityDeposit')" />
        <span-wrapper :txt="`₹ ${amountToPay}`" />
      </div-wrapper>
      <div-wrapper class="sec-msg" :txt="$t('_registrationAmount')" />
      <payment-mode :payOption="payOption" @selectedValue="selectedPayOption" />
    </section>
    <footer class="footer-bottom">
      <button-wrapper
        :btnTxt="$t('_payFor')"
        class="button"
        @buttonClick="doPayment"
      />
    </footer>
  </div-wrapper>
</template>
<script>
import BackIcon from "@/components/svgIcons/BackIcon";
import PaymentMode from "@/components/payment-form/PaymentMode";
import SpanWrapper from "@/components/form/SpanWrapper";
import ButtonWrapper from "@/components/form/ButtonWrapper";
import DivWrapper from "@/components/form/DivWrapper";
import ApiRequest from "@/services/ApiRequest";
import { BASE_API } from "@/utils/constants";
import { GET } from "@/utils/HttpStatusCodeConst";
import { mapGetters } from "vuex";

export default {
  components: {
    DivWrapper,
    ButtonWrapper,
    SpanWrapper,
    PaymentMode,
    BackIcon,
  },

  data() {
    return {
      payOption: "paytm",
      message: "",
      color: "",
      amountToPay: "",
    };
  },
  computed: {
    ...mapGetters({ getUserInfo: "getUserInfo" }),
  },
  created() {
    ApiRequest(BASE_API, `/price/`, GET)
      .then((response) => {
        this.amountToPay = response.amount;
      })
      .catch(() => {
        this.$swal({ text: this.$t("_pleaseTryAgain"), icon: "error" });
      });
    // console.log(this.getUserInfo);
  },
  methods: {
    goBack() {
      this.$router.back(-1);
    },

    selectedPayOption(_payOption) {
      this.payOption = _payOption;
    },

    doPayment() {
      console.log(this.getUserInfo);
      //todo: check for payment amount and redirect to gateway
      let _url = `${BASE_API}/payment/NEW_JOINING?partnerId=${
        this.$route.query.partner_id
          ? this.$route.query.partner_id
          : this.getUserInfo.partner_id
      }&netAmount=${this.amountToPay ? this.amountToPay : 1000}`;
      if (this.payOption === "paytm") {
        _url = `${_url}&gateway=sdfsfsegd`;
      }
      console.log("ur: ", _url);
      window.location.href = _url;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_payment.scss";
</style>
