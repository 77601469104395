<template>
  <div-wrapper className="payment-opt">
    <h3>{{ $t("_pay") }}</h3>
    <div-wrapper className="optionbox">
      <div-wrapper className="input_radicol">
        <label-wrapper className="input-check">
          <span-wrapper className="pay-icon pay_tm">
            <paytm-icon />
          </span-wrapper>
          <radio-btn
            name="payOption"
            :value="payOption"
            label="paytm"
            @handleRadioChange="getRadioValue"
          />
          <span-wrapper className="text-label" :txt="$t('_payFromPAYTM')" />
          <span-wrapper className="checkmark" />
        </label-wrapper>
      </div-wrapper>
    </div-wrapper>
    <div-wrapper className="optionbox">
      <div-wrapper className="input_radicol">
        <label-wrapper className="input-check">
          <span-wrapper className="pay-icon">
            <cod-pay-icon />
          </span-wrapper>
          <radio-btn
            name="payOption"
            :value="payOption"
            label="online"
            @handleRadioChange="getRadioValue"
          />
          <span-wrapper className="text-label" :txt="$t('_payOnline')" />
          <span-wrapper className="checkmark" />
        </label-wrapper>
      </div-wrapper>
    </div-wrapper>
  </div-wrapper>
</template>
<script>
import DivWrapper from "@/components/form/DivWrapper";
import LabelWrapper from "@/components/form/LabelWrapper";
import SpanWrapper from "@/components/form/SpanWrapper";
import CodPayIcon from "@/components/svgIcons/CodPayIcon";
import RadioBtn from "@/components/form/RadioBtn";
import PaytmIcon from "@/components/svgIcons/PaytmIcon";

export default {
  components: {
    RadioBtn,
    SpanWrapper,
    LabelWrapper,
    DivWrapper,
    CodPayIcon,
    PaytmIcon,
  },

  props: {
    payOption: {
      type: String,
      default: "payOnLater",
    },
  },

  methods: {
    getRadioValue(_lable) {
      this.$emit("selectedValue", _lable);
    },
    nice() {
      const payload = {
        aadhaarCardRear: "string",
        aadhaarNumber: "string",
        aadhaarNumberProof: "string",
        address: "string",
        cscLicense: "string",
        mobileNo: "number",
        name: "string",
        pancardNumber: "string",
        pancardNumberProof: "string",
        pincode: "string",
        referralId: "string",
      };
      console.log(payload);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_payment.scss";
</style>
